/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ThemeOptions } from '@mui/material/styles';

export const typography: ThemeOptions = {
	typography: {
		htmlFontSize: 16,
		fontFamily: ['GT-America', 'Roboto', 'sans-serif'].join(','),
		h1: {
			fontSize: 48,
			fontWeight: 900,
			lineHeight: 1,
			fontFamily: ['GT-America-Condensed', 'GT-America', 'Roboto', 'sans-serif'].join(','),
			textTransform: 'uppercase',
		},
		h1_underline: {
			fontSize: 48,
			fontWeight: 900,
			lineHeight: 1,
			fontFamily: ['GT-America-Condensed', 'GT-America', 'Roboto', 'sans-serif'].join(','),
			textTransform: 'uppercase',
			paddingBottom: 8,
			borderBottom: '2px solid black',
			marginBottom: 8,
			display: 'inline-block',
			color: 'black',
		},
		h2: {
			fontSize: 40,
			fontWeight: 900,
			fontFamily: ['GT-America-Condensed', 'GT-America', 'Roboto', 'sans-serif'].join(','),
			textTransform: 'uppercase',
		},
		h2_underline: {
			fontSize: 40,
			fontWeight: 900,
			lineHeight: 1,
			fontFamily: ['GT-America-Condensed', 'GT-America', 'Roboto', 'sans-serif'].join(','),
			textTransform: 'uppercase',
			paddingBottom: 8,
			borderBottom: '2px solid black',
			marginBottom: 8,
			display: 'inline-block',
			color: 'black',
		},
		h3: {
			fontSize: 27,
			fontWeight: 500,
			textTransform: 'uppercase',
		},
		h4: {
			fontSize: 23,
			fontWeight: 500,
			textTransform: 'uppercase',
		},
		h5: {
			fontSize: 20,
			fontWeight: 500,
			textTransform: 'uppercase',
		},
		h6: {
			fontSize: 18,
			fontWeight: 500,
			textTransform: 'uppercase',
		},
		subtitle1: {
			fontSize: 18,
			fontWeight: 700,
			textTransform: 'uppercase',
		},
		subtitle2: {
			fontSize: 18,
			fontWeight: 400,
		},
		body1: {
			fontSize: 16,
			fontWeight: 400,
		},
		body2: {
			fontSize: 16,
			fontWeight: 500,
		},
		button: {
			fontSize: 15,
			fontWeight: 400,
			textTransform: 'none',
		},
		caption: {
			fontSize: 12,
			fontWeight: 400,
		},
		strong: {
			fontWeight: 600,
		},
		overline: {
			fontSize: 12,
			textTransform: 'uppercase',
			fontWeight: 600,
			letterSpacing: 1,
		},
		body1AsH1: {
			fontSize: 16,
			fontWeight: 400,
		},
		body1AsH2: {
			fontSize: 16,
			fontWeight: 400,
		},
		body2AsH2: {
			fontSize: 16,
			fontWeight: 500,
		},
		h3AsH1: {
			fontSize: 27,
			fontWeight: 500,
			textTransform: 'uppercase',
		},
		h4AsH1: {
			fontSize: 23,
			fontWeight: 500,
			textTransform: 'uppercase',
		},
		h6AsH2: {
			fontSize: 18,
			fontWeight: 500,
			textTransform: 'uppercase',
		},
	},
};

declare module '@mui/material/styles' {
	interface TypographyVariants {
		h1_underline: React.CSSProperties;
		h2_underline: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		h1_underline?: React.CSSProperties;
		h2_underline?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		h1_underline: true;
		h2_underline: true;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		inline: true;
		containedOnDark: true;
		outlinedOnDark: true;
	}

	interface ButtonPropsColorOverrides {
		black: true;
		white: true;
	}
}

declare module '@mui/material/IconButton' {
	interface IconButtonPropsColorOverrides {
		black: true;
		white: true;
	}
}

declare module '@mui/material/ButtonGroup' {
	interface ButtonGroupPropsColorOverrides {
		black: true;
		white: true;
	}
}

declare module '@mui/material/SvgIcon' {
	interface SvgIconPropsColorOverrides {
		black: true;
		white: true;
	}
}
