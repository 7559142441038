/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Container: ThemeOptions = {
	components: {
		MuiContainer: {
			defaultProps: {
				maxWidth: false,
				// disableGutters: true,
			},
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						'&:empty': {
							display: 'none',
						},
						'&:is(main > section).MuiContainer-root.MuiContainer-maxWidthXl': {
							display: 'none', // if a layout has containers without widgets, hide the container
							'&:has(> .MuiGrid-root > section.MuiGrid-root > :not(:empty))': {
								display: 'block',
							},
						},
						'&:has(.anti-gap)': {
							marginBottom: { xs: -2, sm: -2, md: -6, lg: -8 },
						},
					},
				}),
			},
		},
	},
};
