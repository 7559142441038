/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DefaultContainerLayout } from '@/data/types/ContainerLayout';
import CustomCategoryPage from '@/data/containers/default/CustomCategoryPage';

export const defaultContainerLayoutManifestCustom: {
	[pageType: string]: DefaultContainerLayout;
} = {
	CustomCategoryPage,
};
