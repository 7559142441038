/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export { cartCalculator, orderCopier } from '../../core/Content/_Cart';
import { dDiv, dFix, getSettings } from '@/data/Settings';
import { DATA_KEY_CART } from '@/data/constants/dataKey';
import { ID } from '@/data/types/Basic';
import { ContentProps } from '@/data/types/ContentProps';
import { Order } from '@/data/types/Order';
import { constructRequestParamsWithPreviewToken } from '@/data/utils/constructRequestParams';
import { getServerSideCommon } from '@/data/utils/getServerSideCommon';
import { shrink } from '@/data/utils/keyUtil';
import { logger } from '@/logging/logger';
import { customCart } from 'integration/generated/custom/index';
import { RequestParams } from 'integration/generated/transactions/http-client';
import { unstable_serialize as unstableSerialize } from 'swr';

export const DATA_KEY = DATA_KEY_CART;

type FullFetcherProps = {
	storeId: string;
	query: Record<string, string | boolean | ID[]>;
	params: RequestParams;
};
const fetcherFull = (pub: boolean) => async (props: FullFetcherProps) => {
	const { storeId, query, params } = props;
	let totalPages = 1;

	const cart = await (customCart(pub).cartGetCart(
		storeId,
		query,
		params
	) as Promise<unknown> as Promise<Order>);

	const { recordSetCount, recordSetTotal, orderItem = [] } = cart;
	const pageSize = dFix(recordSetCount, 0);
	if (pageSize < dFix(recordSetTotal, 0)) {
		totalPages = dFix(Math.ceil(dDiv(recordSetTotal, pageSize)), 0);
	}

	if (totalPages > 1) {
		// generate fetches for remaining pages
		const fetches = Array.from(
			{ length: totalPages - 1 },
			(_empty, index) =>
				customCart(pub).cartGetCart(
					storeId,
					{ ...query, pageNumber: index + 2, pageSize },
					params
				) as Promise<unknown> as Promise<Order>
		);

		// fetch remaining pages concurrently
		const pages = await Promise.all(fetches);

		// collect all order-items
		const allItems = [...orderItem, ...pages.map(({ orderItem }) => orderItem).flat(1)];

		// update the container
		cart.orderItem = allItems;
	}

	return cart;
};

export const fetcher =
	(pub: boolean) =>
	/**
	 * The data fetcher for cart
	 * @param query The request query.
	 * @param params The RequestParams, it contains all the info that a request needed except for 'body' | 'method' | 'query' | 'path'.
	 *                                  we are using it to send cookie header.
	 * @returns Fetched cart data.
	 */
	async (
		storeId: string,
		query: {
			langId: string;
			[key: string]: string | boolean | ID[];
		},
		params: RequestParams
	): Promise<Order | undefined> => {
		try {
			return await fetcherFull(pub)({ storeId, query, params });
		} catch (error: any) {
			if (error.status === 404) {
				return {} as Order;
			}

			logger.error('_Cart: fetcher: error: %o', error);
			if (pub) {
				throw error;
			}
			return undefined;
		}
	};

export const getCart = async ({
	cache,
	id: _id,
	context,
}: ContentProps): Promise<Order | undefined> => {
	const settings = await getSettings(cache, context);
	const { storeId, langId } = getServerSideCommon(settings, context);
	const props = { storeId, query: { langId, sortOrder: 'desc' } };
	const key = unstableSerialize([shrink(props), DATA_KEY]);
	const params: RequestParams = constructRequestParamsWithPreviewToken({ context });
	const value = cache.get(key) || fetcher(false)(props.storeId, props.query, params);
	cache.set(key, value);
	return await value;
};
