/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2024.
 */
export const EMS_USAGE_TYPE_STORE_FEATURE = 'STOREFEATURE';

export const EMS_DATA_TYPE_FEATURE_ENABLED = 'FeatureEnabled';

export const EMS_STORE_FEATURE = {
	ACCOUNT_ACTIVATION: 'AccountActivation',
	ADVANCED_SEARCH: 'AdvancedSearch',
	AGE: 'Age',
	AGE_OPTIONS: 'AgeOptions',
	ALLOW_RE_ORDER: 'AllowReOrder',
	ANALYTICS: 'Analytics',
	APPLE_PAY: 'ApplePay',
	BOPIS: 'BOPIS',
	BROWSING_HISTORY: 'BrowsingHistory',
	CDN_CACHING: 'CDNCaching',
	CATEGORY_PRODUCT_BLOGS: 'CategoryProductBlogs',
	CATEGORY_SORT_ORDER_OPTIONS: 'CategorySortOrderOptions',
	CATEGORY_SORT_ORDER_SEQUENCE: 'CategorySortOrder_Sequence',
	CATEGORY_SORT_ORDER_ALPHANUMERIC: 'CategorySortOrder_alphanumeric',
	CATEGORY_SUBSCRIPTIONS: 'CategorySubscriptions',
	CONSENT_OPTIONS: 'ConsentOptions',
	COUPON_WALLET: 'CouponWallet',
	CUSTOM_LOGO: 'CustomLogo',
	DA_CONSENT: 'DAConsent',
	DATE_OF_BIRTH: 'DateOfBirth',
	DISCOVER: 'Discover',
	EMAIL_OPTION: 'EmailOption',
	ENABLE_QUOTES: 'EnableQuotes',
	EXPANDED_CATEGORY_NAVIGATION: 'ExpandedCategoryNavigation',
	EXPEDITED_ORDERS: 'ExpeditedOrders',
	FACEBOOK_INTEGRATION: 'FacebookIntegration',
	FUTURE_ORDERS: 'FutureOrders',
	GENDER: 'Gender',
	INVENTORY_AVAILABILITY: 'InventoryAvailability',
	KEYWORD_SUGGESTIONS: 'KeywordSuggestions',
	MARKETING_CONSENT: 'MarketingConsent',
	MOBILE_PHONE: 'MobilePhone',
	MULTIPLE_ACTIVE_ORDERS: 'MultipleActiveOrders',
	MULTIPLE_SHIPMENTS: 'MultipleShipments',
	PAYMENT_PROMOTION: 'PaymentPromotion',
	PERMANENT: 'Permanent',
	PHOTO_GALLERY: 'PhotoGallery',
	PRODUCT_RANKINGS: 'ProductRankings',
	PRODUCT_RATINGS_REVIEW: 'ProductRatingsReview',
	PRODUCT_SUGGESTIONS: 'ProductSuggestions',
	PRODUCTION_SERVER: 'ProductionServer',
	PUNCHOUT_PAYMENT: 'PunchoutPayment',
	QUICK_ORDER: 'QuickOrder',
	RATING_REVIEW_INTEGRATION: 'RatingReviewIntegration',
	RECURRING_ORDERS: 'RecurringOrders',
	REMOTE_WIDGET: 'RemoteWidget',
	REQUISITION_LIST: 'RequisitionList',
	SOA_WISHLIST: 'SOAWishlist',
	SEARCH_BASED_NAVIGATION: 'SearchBasedNavigation',
	SESSION: 'Session',
	SHARED_SHIPPING_BILLING_PAGE: 'SharedShippingBillingPage',
	SHIP_AS_COMPLETE: 'ShipAsComplete',
	SHIPPING_CHARGE_TYPE: 'ShippingChargeType',
	SHIPPING_INSTRUCTIONS: 'ShippingInstructions',
	SHOW_HIDE_ORDER_ITEMS: 'ShowHideOrderItems',
	SIDE_BY_SIDE_INTEGRATION: 'SideBySideIntegration',
	STORE_LOCATOR: 'StoreLocator',
	SUBSCRIPTION: 'Subscription',
	TEALEAF: 'Tealeaf',
	TRACKING_STATUS: 'TrackingStatus',
	USE_COMMERCE_COMPOSER: 'UseCommerceComposer',
	USE_SYNONYMS: 'UseSynonyms',
	USER_RATINGS: 'UserRatings',
	WCH_INTEGRATION: 'WCHIntegration',
	ACCOUNT_PARTICIPANT_ROLE: 'accountParticipantRole',
	ALLOW_ORG_REGISTRATION: 'allowOrgRegistration',
	CONTRACT_SELECTION: 'contractSelection',
	ON_BEHALF_OF: 'on-behalf-of',
	ON_BEHALF_OF_CSR: 'on-behalf-of-csr',
	PREFERRED_CURRENCY: 'preferredCurrency',
	PREFERRED_LANGUAGE: 'preferredLanguage',
	PROMOTION_CODE: 'promotionCode',
	QUICK_CHECKOUT: 'quickCheckout',
	SEARCH: 'search',
	STERLING_CONFIGURATOR: 'sterlingConfigurator',
};
