/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { buttonVariants } from '@/styles/Centro/Button/variants';
import { buttonVariantsStyles } from '@/styles/Centro/Button/variantsStyles';
import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { Theme } from '@mui/material';
import { alpha, ThemeOptions } from '@mui/material/styles';

type StateTheme = {
	ownerState: { className?: string; variant?: string; color?: string };
	theme: Theme;
};

export const Button: ThemeOptions = {
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			variants: buttonVariants,
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						// py: 'calc(1.5 * 8px - 3px)',
						// px: 'calc(2 * 8px - 3px)',
						// minWidth: 'auto',
						'&:hover': {
							// backgroundColor: 'transparent',
							// border: `3px solid button.primary`,
						},
						'&.Mui-disabled': {
							// color: 'button.disabledText',
						},
						'&.Mui-focusVisible': {
							outline: '1px solid black',
						},
					},
					variants: buttonVariantsStyles,
				}),
				contained: ({ ownerState, theme }: StateTheme) =>
					theme.unstable_sx({
						...{},
						...({
							fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
							fontWeight: 700,
							textTransform: 'uppercase',
							borderWidth: '3px',
							borderStyle: 'solid',
							padding: `calc(${theme.spacing()} - 3px) calc(2 * ${theme.spacing()} - 3px)`,
							borderColor: () => {
								if (ownerState.color?.toString() === 'black') {
									return theme.palette.black.main;
								} else if (ownerState.color?.toString() === 'white') {
									return theme.palette.white.main;
								} else if (ownerState.color?.toString() === 'secondary') {
									return theme.palette.secondary.main;
								} else return theme.palette.primary.main;
							},

							'&[disabled]': {
								borderColor: 'button.disabled',
							},

							'&:hover': {
								backgroundColor: 'transparent',
								color: () => {
									if (ownerState.color?.toString() === 'black') {
										return theme.palette.black.main;
									} else if (ownerState.color?.toString() === 'white') {
										return theme.palette.white.main;
									} else if (ownerState.color?.toString() === 'secondary') {
										return theme.palette.secondary.main;
									} else return theme.palette.primary.main;
								},
							},
						} as Record<string, unknown>),
					}),
				outlined: ({ ownerState, theme }: StateTheme) =>
					theme.unstable_sx({
						...{},
						...({
							fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
							fontWeight: 700,
							textTransform: 'uppercase',
							borderWidth: '3px',
							borderColor: () => {
								if (ownerState.color?.toString() === 'black') {
									return theme.palette.black.main;
								} else if (ownerState.color?.toString() === 'white') {
									return theme.palette.white.main;
								} else return theme.palette.primary.main;
							},

							'&[disabled]': {
								borderColor: 'button.disabled',
							},

							'&:hover': {
								borderWidth: '3px',
								backgroundColor: () => {
									if (ownerState.color?.toString() === 'black') {
										return theme.palette.black.main;
									} else if (ownerState.color?.toString() === 'white') {
										return theme.palette.white.main;
									} else return theme.palette.primary.main;
								},
								color: () => {
									if (ownerState.color?.toString() === 'black') {
										return theme.palette.white.main;
									} else if (ownerState.color?.toString() === 'white') {
										return theme.palette.black.main;
									} else return theme.palette.white.main;
								},
							},

							'&:active': {
								backgroundColor: () => {
									if (ownerState.color?.toString() === 'black') {
										return alpha(theme.palette.black.main || '#000000', 0.5);
									} else if (ownerState.color?.toString() === 'white') {
										return alpha(theme.palette.white.main || '#000000', 0.5);
									} else return alpha(theme.palette.primary.main || '#000000', 0.5);
								},
								color: () => {
									if (ownerState.color?.toString() === 'black') {
										return theme.palette.white.main;
									} else if (ownerState.color?.toString() === 'white') {
										return theme.palette.black.main;
									} else return theme.palette.white.main;
								},
							},
						} as Record<string, unknown>),
					}),
				text: getStyleOverrides({
					styles: {
						fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
						fontWeight: 700,
						textTransform: 'uppercase',
					},
				}),
			},
		},
	},
};
