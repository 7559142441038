/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

// import { Layout } from '@/data/types/Layout';
// import { FC } from 'react';
// import { CustomCategoryPage } from '@/components/layouts/CustomCategoryPage';

// export const layoutManifestCustom: {
// 	[key: string]: FC<{ layout: Layout }>;
// } = {
// 	CustomCategoryPage,
// };

import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { layoutMainSX } from '@/components/layouts/styles/main';
import { Layout } from '@/data/types/Layout';
import { Container, Grid, Stack, useTheme } from '@mui/material';
import { FC } from 'react';

export const CustomCategoryPage: FC<{ layout: Layout }> = ({ layout }) => {
	const {
		dimensions: { contentSpacing },
	} = useTheme();
	return layout ? (
		<>
			<LayoutSlot slot={layout.slots.header} />
			<Stack
				component="main"
				gap={contentSpacing}
				alignItems="center"
				sx={layoutMainSX(layout.slots.first?.length || 0)}
			>
				<LayoutSlot slot={layout.slots.first} ItemWrap={ItemWrap} />
				<Container component="section" maxWidth="xl">
					<Grid container spacing={contentSpacing}>
						<Grid item component="section" xs={12} md={4} lg={3}>
							<Stack gap={contentSpacing}>
								<LayoutSlot slot={layout.slots.second} />
							</Stack>
						</Grid>
						<Grid item component="section" xs={12} md={8} lg={9}>
							<Stack gap={contentSpacing}>
								<LayoutSlot slot={layout.slots.third} />
							</Stack>
						</Grid>
					</Grid>
				</Container>
				<LayoutSlot slot={layout.slots.fourth} ItemWrap={ItemWrap} />
				<LayoutSlot slot={layout.slots.footer} />
			</Stack>
		</>
	) : null;
};
