import { Button } from '@/styles/Centro/Button';
import { Container } from '@/styles/Centro/Container';
import { Paper } from '@/styles/Centro/Paper';
import { TextField } from '@/styles/Centro/TextField';
import { Tooltip } from '@/styles/Centro/Tooltip';
import { breakpoints } from '@/styles/Centro/breakpoints';
import { fonts } from '@/styles/Centro/fonts';
import { palette } from '@/styles/Centro/palette';
import { shape } from '@/styles/Centro/shape';
import { typography } from '@/styles/Centro/type';
import { ThemeManifestTheme } from '@/styles/manifest';

export const CentroTheme: ThemeManifestTheme = {
	inheritFrom: 'Ruby',
	components: [
		shape,
		typography,
		palette,
		Button,
		Container,
		Paper,
		TextField,
		Tooltip,
		breakpoints,
		fonts,
	],
};
