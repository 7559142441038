/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ID } from '@/data/types/Basic';
import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

export const contentManifestCustom: Record<string, ComponentType<{ id: ID }>> = {
	BreadcrumbTrail: dynamic(() =>
		import('@/components/content/BreadcrumbTrail').then((mod) => mod.BreadcrumbTrail)
	),
	Header: dynamic(() => import('@/components/content/Header').then((mod) => mod.Header)),
	HeaderHomepage: dynamic(() => import('@/components/content/Header').then((mod) => mod.Header)),
	Footer: dynamic(() => import('@/components/content/Footer').then((mod) => mod.Footer)),
	CustomSimpleTemplate: dynamic(() =>
		import('@/components/content/CustomSimpleTemplate').then((mod) => mod.CustomSimpleTemplate)
	),
	CustomTemplate: dynamic(() =>
		import('@/components/content/CustomTemplate').then((mod) => mod.CustomTemplate)
	),
	CustomCategoryPage: dynamic(() =>
		import('@/components/content/CustomCategoryPage').then((mod) => mod.CustomCategoryPage)
	),
	// MySampleSite: dynamic(() =>
	// 	import('@/components/content/MySampleSite').then((mod) => mod.MySampleSite)
	// ),
	CategoryTitle: dynamic(() =>
		import('@/components/content/CategoryTitle').then((mod) => mod.CategoryTitle)
	),
	CategoryShortDesc: dynamic(() =>
		import('@/components/content/CategoryShortDesc').then((mod) => mod.CategoryShortDesc)
	),
	CategoryLongDesc: dynamic(() =>
		import('@/components/content/CategoryLongDesc').then((mod) => mod.CategoryLongDesc)
	),
	CategoryImage: dynamic(() =>
		import('@/components/content/CategoryImage').then((mod) => mod.CategoryImage)
	),
	InvoiceDetails: dynamic(() =>
		import('presentation/components/fiwe/pages/InvoiceDetails').then(
			(mod) => mod.InvoiceDetailsPage
		)
	),
	Invoices: dynamic(() =>
		import('presentation/components/fiwe/pages/InvoicesPage').then((mod) => mod.InvoicesPage)
	),
	Dashboard: dynamic(() =>
		import('presentation/components/fiwe/pages/DashboardPage').then((mod) => mod.DashboardPage)
	),
	CentroCheckout: dynamic(() =>
		import('presentation/components/fiwe/content/CentroCheckout').then((mod) => mod.CentroCheckout)
	),
	CategorySeriesProductList: dynamic(() =>
		import('presentation/components/fiwe/widgets/CategorySeriesProductListWidget').then(
			(mod) => mod.CategorySeriesProductListWidget
		)
	),
	CategoryMarketingConcept: dynamic(() =>
		import('presentation/components/fiwe/widgets/CategoryMarketingConceptWidget').then(
			(mod) => mod.CategoryMarketingConceptWidget
		)
	),
	FiweTest: dynamic(() =>
		import('presentation/components/fiwe/widgets/FiweTestWidget').then((mod) => mod.FiweTestWidget)
	),
	CategoryMarketingConcepts: dynamic(() =>
		import('presentation/components/fiwe/widgets/CategoryMarketingConceptsWidget').then(
			(mod) => mod.CategoryMarketingConceptsWidget
		)
	),
};
