/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { fonts as baseFonts } from '@/styles/Base/fonts';
import { ThemeOptions } from '@mui/material/styles';

export const fonts = (basePath: string): ThemeOptions => ({
	fonts: [
		...(<[]>baseFonts(basePath).fonts), // Roboto
		{
			family: 'GT-America',
			style: 'normal',
			weight: 100, // not found in gt america
			display: 'swap',
			src: `url(${basePath}/fonts/Roboto-Thin.ttf) format('truetype')`,
		},
		{
			family: 'GT-America',
			style: 'normal',
			weight: 300, // not found in gt america
			display: 'swap',
			src: `url(${basePath}/fonts/Roboto-Light.ttf) format('truetype')`,
		},
		{
			family: 'GT-America',
			style: 'normal',
			weight: 400,
			display: 'swap',
			src: `url(${basePath}/fonts/GT-America/GT-America-Standard-Regular.woff2) format('woff2'),
						url(${basePath}/fonts/GT-America/GT-America-Standard-Regular.ttf) format("truetype")`,
		},
		{
			family: 'GT-America',
			style: 'italic',
			weight: 400,
			display: 'swap',
			src: `url(${basePath}/fonts/Roboto-Italic.ttf) format('truetype')`,
		},
		{
			family: 'GT-America',
			style: 'normal',
			weight: 500,
			display: 'swap',
			src: `url(${basePath}/fonts/GT-America/GT-America-Standard-Medium.woff2) format('woff2'),
						url(${basePath}/fonts/GT-America/GT-America-Standard-Medium.ttf) format('truetype')`,
		},
		{
			family: 'GT-America',
			style: 'normal',
			weight: 700,
			display: 'swap',
			src: `url(${basePath}/fonts/GT-America/GT-America-Standard-Bold.woff2) format('woff2'),
						url(${basePath}/fonts/GT-America/GT-America-Standard-Bold.ttf) format('truetype')`,
		},
		{
			family: 'GT-America',
			style: 'normal',
			weight: 900,
			display: 'swap',
			src: `url(${basePath}/fonts/GT-America/GT-America-Standard-Black.woff2) format('woff2'),
						url(${basePath}/fonts/GT-America/GT-America-Standard-Black.ttf) format('truetype')`,
		},
		// GT-America-Condensed
		{
			family: 'GT-America-Condensed',
			style: 'normal',
			weight: 700,
			display: 'swap',
			src: `url(${basePath}/fonts/GT-America/GT-America-Condensed-Bold.woff2) format('woff2'),
						url(${basePath}/fonts/GT-America/GT-America-Condensed-Bold.ttf) format('truetype')`,
		},
		{
			family: 'GT-America-Condensed',
			style: 'normal',
			weight: 900,
			display: 'swap',
			src: `url(${basePath}/fonts/GT-America/GT-America-Condensed-Black.woff2) format('woff2'),
						url(${basePath}/fonts/GT-America/GT-America-Condensed-Black.ttf) format('truetype')`,
		},
		// Roboto
		{
			family: 'Roboto',
			style: 'normal',
			weight: 400,
			display: 'swap',
			src: `url(${basePath}/fonts/Roboto-Regular.ttf) format("truetype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
	],
});
