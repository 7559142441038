/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { getCustomCategoryPage } from '@/data/containers/CustomCategoryPage';
import { getCustomSimpleTemplate } from '@/data/containers/CustomSimpleTemplate';
import { getCustomTemplate } from '@/data/containers/CustomTemplate';
import { getInvoiceDetailsPage } from '@/data/containers/InvoiceDetailsPage';
import { RouteProtection } from '@/data/containers/manifest';
import { Settings } from '@/data/Settings';
import { IncomingContent } from '@/data/types/IncomingContent';
import { Order } from '@/data/types/Order';
// import { getMyProductTestPage } from '@/data/containers/MyProductTestPage';
import { getCentroCheckoutPage } from '@/data/containers/CentroCheckoutPage';
import { getDashboardPage } from '@/data/containers/DashboardPage';
import { getInvoicesPage } from '@/data/containers/InvoicesPage';
import { Layout } from '@/data/types/Layout';
import { User } from '@/data/User';
import { validateProtectedRoute } from '@/data/utils/validateProtectedRoute';
import { TranslationTable } from 'integration/generated/translations';

const layoutManifest = {
	CustomSimpleTemplate: getCustomSimpleTemplate,
	CustomTemplate: getCustomTemplate,
	CustomCategoryPage: getCustomCategoryPage,
	InvoicesPage: getInvoicesPage,
	InvoiceDetailsPage: getInvoiceDetailsPage,
	DashboardPage: getDashboardPage,
	CheckOutPage: getCentroCheckoutPage,
	// MyProductTestPage: getMyProductTestPage,
};

export const dataContainerManifestCustom: {
	[key: string]: (props: IncomingContent) => Layout;
} = layoutManifest;

// export const dataContainerManifestCustom: {
// 	[key: string]: (cLayout: ContainerLayout) => Layout;
// } = layoutManifest;

type LayoutKeys = keyof typeof layoutManifest;
type LocalRoutes = TranslationTable['Routes'];

/**
 * Layout relationships with translation route name as the key and
 * the layoutManifest key as the value.
 */
export const dataRouteManifestCustom: Record<string, LayoutKeys> = {
	Invoices: 'InvoicesPage',
	InvoiceDetails: 'InvoiceDetailsPage',
	Dashboard: 'DashboardPage',
	CheckOut: 'CheckOutPage',
};

/**
 * Map of routes that are dependent on the user status for conditional
 * redirection or other protection. The method return dictates the
 * behavior:
 * 	- true: allow access
 * 	- false: do not load the route
 * 	- string: redirect to the route named in the string
 */
export const dataRouteProtectionCustom: Partial<
	Record<
		keyof LocalRoutes,
		(user: Partial<User>, _cart?: Order | boolean, settings?: Settings) => RouteProtection
	>
> = {
	Invoices: (user, _cart, settings) =>
		validateProtectedRoute({ user, settings }, ['b2b', 'login', 'buyerAdmin']),
	InvoiceDetails: (user, _cart, settings) =>
		validateProtectedRoute({ user, settings }, ['b2b', 'login', 'buyerAdmin']),
	Dashboard: (user, _cart, settings) =>
		validateProtectedRoute({ user, settings }, ['b2b', 'login']),
	CheckOut: (user, _cart, settings) => validateProtectedRoute({ user, settings }, ['b2b', 'login']),
};
