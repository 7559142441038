/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Layout } from '@/data/types/Layout';
import { CustomTemplate } from '@/components/layouts/CustomTemplate';
import { CustomSimpleTemplate } from '@/components/layouts/CustomSimpleTemplate';
import { CustomCategoryPage } from '@/components/layouts/CustomCategoryPage';
import { TwoColumn } from '@/components/layouts/TwoColumn';
import { FC } from 'react';

export const layoutManifestCustom: {
	[key: string]: FC<{ layout: Layout }>;
} = {
	CustomTemplate,
	CustomSimpleTemplate,
	CustomCategoryPage,
	TwoColumn,
};
