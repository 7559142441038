import { Button } from '@/styles/Kakeldax/Button';
import { breakpoints } from '@/styles/Kakeldax/breakpoints';
import { palette } from '@/styles/Kakeldax/palette';
import { shape } from '@/styles/Kakeldax/shape';
import { typography } from '@/styles/Kakeldax/type';
import { ThemeManifestTheme } from '@/styles/manifest';

export const KakeldaxTheme: ThemeManifestTheme = {
	inheritFrom: 'Centro',
	components: [shape, typography, palette, Button, breakpoints],
};
