/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { CentroTheme } from '@/styles/Centro/theme';
import { KakeldaxTheme } from '@/styles/Kakeldax/theme';
import { ThemeManifest } from '@/styles/manifest';

export const themeManifestCustom: ThemeManifest = {
	defaultTheme: 'Centro',
	themes: { Centro: CentroTheme, Kakeldax: KakeldaxTheme },
};
