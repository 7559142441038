/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
import { getSettings } from '@/data/Settings';
import { DATA_KEY_ORGANIZATION_DESCRIPTIONS } from '@/data/constants/dataKey';
import { Cache } from '@/data/types/Cache';
import { OrganizationResponse } from '@/data/types/Organization';
import { constructRequestParamsWithPreviewToken } from '@/data/utils/constructRequestParams';
import { getServerSideCommon } from '@/data/utils/getServerSideCommon';
import { shrink } from '@/data/utils/keyUtil';
import { error as logError } from '@/data/utils/loggerUtil';
import { transactionsOrganization } from 'integration/generated/transactions';
import { RequestParams } from 'integration/generated/transactions/http-client';
import { GetServerSidePropsContext } from 'next';
import { unstable_serialize as unstableSerialize } from 'swr';

export type OrgFetcherProfileNameType =
	| 'IBM_Organization_Short_Summary'
	| 'IBM_Organization_Summary'
	| 'IBM_Organization_Details'
	| 'IBM_Org_Entity_Details'
	| 'IBM_Assigned_Roles_Details'
	| 'IBM_Parent_Assigned_Roles_Details';

export const orgFetcher =
	(pub: boolean, context?: GetServerSidePropsContext) =>
	async ({
		storeId,
		organizationId,
		query,
		params,
	}: {
		storeId: string;
		organizationId: string;
		query?: {
			profileName?: OrgFetcherProfileNameType;
		};
		params: RequestParams;
	}): Promise<OrganizationResponse | undefined> => {
		try {
			return await transactionsOrganization(
				pub
			).organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName(
				storeId,
				organizationId,
				query,
				params
			);
		} catch (error: any) {
			logError(context?.req, 'Organization: orgFetcher: error: %o', error);
			return undefined;
		}
	};

export const getOrg = async (
	cache: Cache,
	context: GetServerSidePropsContext,
	organizationId: string
): Promise<OrganizationResponse> => {
	const settings = await getSettings(cache, context);
	const { storeId, langId } = getServerSideCommon(settings, context);
	const params: RequestParams = constructRequestParamsWithPreviewToken({ context });
	const props = { storeId, langId };
	const key = unstableSerialize([shrink(props), DATA_KEY_ORGANIZATION_DESCRIPTIONS]);
	const value = cache.get(key) ?? orgFetcher(false, context)({ storeId, organizationId, params });

	cache.set(key, value);
	return await value;
};
