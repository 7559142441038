/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const buttonVariantsStyles: Record<string, SxProps> = {
	inline: {
		color: 'primary.main',
		'&:hover': {
			color: 'primary.dark',
		},
	},
	containedOnDark: {
		backgroundColor: 'button.primary',
		color: 'button.contrastText',
		borderWidth: '3px',
		borderStyle: 'solid',
		borderColor: 'button.primary',

		'&:hover': {
			backgroundColor: 'transparent',
			borderColor: 'button.contrastText',
		},
	},
	outlinedOnDark: {
		backgroundColor: 'transparent',
		color: 'button.contrastText',
		borderWidth: '3px',
		borderStyle: 'solid',
		borderColor: 'button.contrastText',

		'&:hover': {
			backgroundColor: 'button.primary',
			borderColor: 'button.primary',
		},
	},
};
