/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { grey } from '@mui/material/colors';
import { ThemeOptions, alpha, darken, getContrastRatio, lighten } from '@mui/material/styles';

declare module '@mui/material/styles' {
	// allow configuration using `createTheme`
	export interface TypeCustomColor {
		main?: string | undefined;
		light?: string | undefined;
		dark?: string | undefined;
		contrastText?: string | undefined;
	}
	export interface TypeButton {
		primary: string;
		primaryHover: string;
		secondary: string;
		secondaryHover: string;
		contrastText: string;
		disabled: string;
		disabledText: string;
	}

	export interface TypeBackground {
		main?: string | undefined;
		default: string;
		paper: string;
		primary: string;
		transparency?: string | undefined;
		contrastText?: string | undefined;
	}

	export interface TypeComponent {
		header: any;
		footer: any;
		basicColors: any;
	}

	export interface PaletteOptions {
		button?: Partial<TypeButton>;
		component?: Partial<TypeComponent>;
		black?: TypeCustomColor;
		white?: TypeCustomColor;
	}

	export interface Palette {
		button: TypeButton;
		component: TypeComponent;
		background: TypeBackground;
		black: TypeCustomColor;
		white: TypeCustomColor;
	}
}

const backgroundWhite = '#fffffe'; // fdfdfc
const centroWhite = '#E1E1E1';
const centroBlack = '#1E1E1E';

const centroBeige = '#dad1b2';
const centroSand = '#bfb8a9';
const centroOliv = '#848870';
const centroTegel = '#bf915b';
const centroKvall = '#3e545b';
const centroSten = '#55595b';

const colorBody = '#2e2e2e';

const inputFieldBackground = grey[50];
const inputFieldBackgroundDisabled = '#ddcfcf';

const components = {
	header: {
		top: {
			background: backgroundWhite,
			text: centroBlack,
			search: {
				hiddenWhenNotFocused: false,
			},
		},
		navbar: {
			background: backgroundWhite,
			text: centroBlack,
		},
	},
	footer: {
		background: centroSand,
		text: centroBlack,
		link: centroBlack,
	},
	basicColors: {
		beige: centroBeige,
		sand: centroSand,
		oliv: centroOliv,
		tegel: centroTegel,
		kvall: centroKvall,
		sten: centroSten,
	},
};

export const palette: ThemeOptions = {
	palette: {
		component: { ...components },
		primary: {
			main: centroTegel,
			light: lighten(centroTegel, 0.1),
			dark: darken(centroTegel, 0.1),
			contrastText: getContrastRatio(centroTegel, '#fff') > 2.8 ? grey[50] : grey[900],
		},
		secondary: {
			main: centroBlack,
			light: lighten(centroBlack, 0.1),
			dark: darken(centroBlack, 0.2),
			contrastText: getContrastRatio(centroBlack, '#fff') > 4.5 ? grey[50] : grey[900],
		},
		text: {
			main: '#43484d',
			primary: colorBody, // body
			secondary: '#5e6977',
			disabled: '#b5beca',
			highlight: '#93101c',
			alert: '#c10c0d',
			expandedMenu: getContrastRatio(centroBlack, '#fff') < 4.5 ? grey[50] : grey[900], // '#ffffff', // used on category bar in header
		},
		base: {
			main: '#43484d',
			contrastText: '#ffffff',
		},
		action: {
			active: '#cccccc',
			hover: '#f0f4f7',
			disabled: '#75767a',
			disabledBackground: alpha(centroBlack, 0.05),
		},
		background: {
			main: '#ffeeca',
			default: backgroundWhite,
			paper: '#fffffe',
			primary: lighten(centroTegel, 0.75), // used as a muted color background. e.g. for project search background
			transparency: 'cc', // used to combine hex colors with transparency (00-ff), e.g., #ffffffcc
			contrastText: '#43484d',
		},
		divider: 'rgba(0, 0, 0, 0.12)',
		border: {
			dark: '#4c5256',
			alert: '#c10c0d',
			footer: '#888',
		},
		textField: {
			border: darken(centroOliv, 0.2), // '#660b13',
			borderHovered: darken(centroOliv, 0.8), // '#3a060b',
			background: inputFieldBackground,
			disabledBackground: inputFieldBackgroundDisabled,
		},
		button: {
			primary: centroTegel,
			primaryHover: lighten(centroTegel, 0.2),
			secondary: '#ffffff',
			secondaryHover: lighten(centroTegel, 0.95),
			contrastText: '#fdfdfd',
			disabled: alpha(centroBlack, 0.05),
			disabledText: '#a1a5ab',
		},
		black: {
			main: centroBlack,
			light: lighten(centroBlack, 0.1),
			dark: darken(centroBlack, 0.2),
			contrastText: getContrastRatio(centroBlack, '#fff') > 4.5 ? grey[50] : grey[900],
		},
		white: {
			main: '#fefefe',
			light: lighten('#fefefe', 0.1),
			dark: darken('#fefefe', 0.2),
			contrastText: getContrastRatio('#fefefe', '#fff') > 4.5 ? grey[50] : grey[900],
		},
	},
} as ThemeOptions;
