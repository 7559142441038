/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Layout } from '@/data/types/Layout';

export const getLoginPage = (): Layout => ({
	name: 'TwoColumn',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: [{ name: 'EMarketingSpot', id: 'login-top', properties: { emsName: 'login-top' } }],
		second: [
			{ name: 'EMarketingSpot', id: 'login-left', properties: { emsName: 'login-left' } },
			{ name: 'Login', id: 'login' },
		],
		third: [{ name: 'EMarketingSpot', id: 'login-right', properties: { emsName: 'login-right' } }],
		fourth: [
			{ name: 'EMarketingSpot', id: 'login-bottom', properties: { emsName: 'login-bottom' } },
		],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});
