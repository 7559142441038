/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DefaultContainerLayout } from '@/data/types/ContainerLayout';

const Layout: DefaultContainerLayout = {
	name: 'CustomCategoryPage',
	cotainerName: 'CustomCategoryPage',
	isStoreDefault: true,
	slots: [
		{
			id: '1',
			widgets: [
				{
					widgetName: 'breadcrumb-trail-widget',
					name: 'BreadcrumbTrail',
					sequence: 0,
				},
				{
					widgetName: 'category-title-widget',
					name: 'CategoryTitle',
					sequence: 0,
				},
			],
		},
		{
			id: '2',
			widgets: [
				{
					widgetName: 'facet-navigation-widget',
					name: 'FacetNavigation',
					sequence: 0,
				},
				{
					widgetName: 'category-image-widget',
					name: 'CategoryImage',
					sequence: 0,
				},
			],
		},
		{
			id: '3',
			widgets: [
				{
					widgetName: 'category-short-desc-widget',
					name: 'CatalogShortDesc',
					sequence: 0,
				},
				{
					widgetName: 'category-long-desc-widget',
					name: 'CatalogLongDesc',
					sequence: 0,
				},
				{
					widgetName: 'child-category-grid-widget',
					name: 'CategoryGrid',
					sequence: 0,
				},
				{
					widgetName: 'catalog-entry-list-widget',
					name: 'CatalogEntryList',
					sequence: 0,
				},
			],
		},
		{
			id: '4',
			widgets: [
				{
					widgetName: 'category-recommendation-widget',
					name: 'CategoryRecommendation',
					sequence: 0,
				},
			],
		},
	],
};

export default Layout;
