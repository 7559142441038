import { Layout } from '@/data/types/Layout';

export const getCentroCheckoutPage = (): Layout => ({
	name: 'TripleStack',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: [
			{ name: 'EMarketingSpot', id: 'checkout-top', properties: { emsName: 'checkout-top' } },
		],
		second: [{ name: 'CentroCheckout', id: 'centro-checkout' }],
		third: [
			{ name: 'EMarketingSpot', id: 'checkout-bottom', properties: { emsName: 'checkout-bottom' } },
		],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});
