/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Container } from '@mui/material';
import { FC } from 'react';

export const ItemWrap: FC = (props) => (
	<Container {...props} maxWidth="xl" disableGutters={false} />
);
