import { Theme } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';

export const Tooltip: ThemeOptions = {
	components: {
		MuiTooltip: {
			defaultProps: {},
			styleOverrides: {
				tooltip: ({ theme }: { theme: Theme }) =>
					theme.unstable_sx({
						...{
							backgroundColor: 'secondary.main',
							color: 'secondary.contrastText',
							fontWeight: 'normal',
							padding: `${theme.spacing(0.75)} ${theme.spacing(1.25)}`,
						},
					}),
			},
		},
	},
};
