/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { IncomingContent } from '@/data/types/IncomingContent';
import { Layout } from '@/data/types/Layout';
import { getContentItemForSlot } from '@/data/utils/getContentItemForSlot';

export const getCustomTemplate = (props: IncomingContent): Layout => ({
	name: 'CustomTemplate',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: getContentItemForSlot(props, 'first'),
		second: getContentItemForSlot(props, 'second'),
		third: getContentItemForSlot(props, 'third'),
		fourth: getContentItemForSlot(props, 'fourth'),
		fifth: getContentItemForSlot(props, 'fifth'),
		sixth: getContentItemForSlot(props, 'sixth'),
		seventh: getContentItemForSlot(props, 'seventh'),
		eighth: getContentItemForSlot(props, 'eighth'),
		ninth: getContentItemForSlot(props, 'ninth'),
		tenth: getContentItemForSlot(props, 'tenth'),
		eleventh: getContentItemForSlot(props, 'eleventh'),
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});
