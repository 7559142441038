/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { grey } from '@mui/material/colors';
import { ThemeOptions, alpha, darken, getContrastRatio, lighten } from '@mui/material/styles';

const KakeldaxBlue = '#4bccf0';
const KakeldaxBlueButton = '#31a5df';
const KakeldaxBlack = '#1e1e1e';
const KakeldaxGreen = '#478c8d';

const colorBody = '#2e2e2e';

const backgroundPaper = '#ffffff';
const backgroundPaperContrastText = '#43484d';

const inputFieldBackground = grey[50];
const inputFieldBackgroundDisabled = '#ddcfcf';

const components = {
	header: {
		top: {
			background: backgroundPaper,
			text: backgroundPaperContrastText,
			search: {
				hiddenWhenNotFocused: false,
			},
		},
		navbar: {
			background: backgroundPaper,
			text: backgroundPaperContrastText,
		},
	},
	footer: {
		background: backgroundPaper,
		text: backgroundPaperContrastText,
		link: false,
	},
};

export const palette: ThemeOptions = {
	palette: {
		component: { ...components },
		primary: {
			main: KakeldaxGreen,
			light: lighten(KakeldaxGreen, 0.1),
			dark: darken(KakeldaxGreen, 0.1),
			contrastText: getContrastRatio(KakeldaxGreen, '#fff') > 3.8 ? grey[50] : grey[900],
		},
		secondary: {
			main: KakeldaxBlack,
			light: lighten(KakeldaxBlack, 0.1),
			dark: darken(KakeldaxBlack, 0.2),
			contrastText: getContrastRatio(KakeldaxBlack, '#fff') > 4.5 ? grey[50] : grey[900],
		},
		text: {
			main: '#43484d',
			primary: colorBody, // body
			secondary: '#5e6977',
			disabled: '#b5beca',
			highlight: '#93101c',
			alert: '#c10c0d',
			expandedMenu: getContrastRatio(KakeldaxBlack, '#fff') < 4.5 ? grey[50] : grey[900], // '#ffffff', // used on category bar in header
		},
		base: {
			main: '#43484d',
			contrastText: '#ffffff',
		},
		action: {
			active: '#cccccc',
			hover: '#f0f4f7',
			disabled: '#75767a',
			disabledBackground: alpha(KakeldaxBlack, 0.05),
		},
		background: {
			main: '#fdfdfc',
			default: '#fafafb',
			paper: backgroundPaper,
			transparency: 'cc', // used to combine hex colors with transparency (00-ff), e.g., #ffffffcc
			contrastText: backgroundPaperContrastText,
		},
		divider: 'rgba(0, 0, 0, 0.12)',
		border: {
			dark: '#4c5256',
			alert: '#c10c0d',
			footer: '#888',
		},
		textField: {
			border: darken(KakeldaxGreen, 0.2), // '#660b13',
			borderHovered: darken(KakeldaxGreen, 0.8), // '#3a060b',
			background: inputFieldBackground,
			disabledBackground: inputFieldBackgroundDisabled,
		},
		button: {
			primary: KakeldaxGreen,
			primaryHover: lighten(KakeldaxGreen, 0.2),
			secondary: '#ffffff',
			secondaryHover: lighten(KakeldaxGreen, 0.95),
			contrastText: '#fdfdfd',
			disabled: alpha(KakeldaxBlack, 0.05),
			disabledText: '#a1a5ab',
		},
	},
} as ThemeOptions;
