/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { buttonVariants } from '@/styles/Kakeldax/Button/variants';
import { buttonVariantsStyles } from '@/styles/Kakeldax/Button/variantsStyles';
import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Button: ThemeOptions = {
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
				// disableElevation: true,
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				disableRipple: true,
				// disableElevation: true,
			},
		},
		MuiButton: {
			variants: buttonVariants,
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						py: 'calc(1.5 * 8px - 3px)',
						px: 'calc(2 * 8px - 3px)',
						minWidth: 'auto',
						fontWeight: 600,
						boxShadow: 'none',

						'&.Mui-disabled': {
							color: 'button.disabledText',
						},
					},
					variants: buttonVariantsStyles,
				}),
				contained: getStyleOverrides({
					styles: {
						borderWidth: '3px',
						borderStyle: 'solid',
						borderColor: 'button.primary',
						backgroundColor: 'button.primary',
						color: 'button.contrastText',

						'&[disabled]': {
							borderColor: 'button.disabled',
						},

						'&:hover': {
							backgroundColor: 'transparent',
							borderWidth: '3px',
							borderStyle: 'solid',
							borderColor: 'button.primary',
							boxShadow: 'none',
							color: 'button.primary',
						},
					},
				}),
				outlined: getStyleOverrides({
					styles: {
						borderWidth: '3px',
						borderStyle: 'solid',
						borderColor: 'button.primary',
						'&:hover': {
							borderWidth: '3px',
							backgroundColor: 'button.primary',
							color: 'button.contrastText',
						},
					},
				}),
				text: getStyleOverrides({
					styles: {
						boxShadow: 'none',
						minWidth: 'unset',
						color: 'primary.main',
						'&:hover': {
							color: 'primary.dark',
						},
					},
				}),
			},
		},
	},
};
